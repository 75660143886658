import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import Gallery from '@browniebroke/gatsby-image-gallery';
import { graphql } from 'gatsby';
import { List } from '../../blocks';
export const query = graphql`
  query themeExpirement {
    images: allFile(
      filter: {
        relativeDirectory: { eq: "theme-expirement-by-uselayouteffect" }
      }
      sort: { fields: name }
    ) {
      edges {
        node {
          publicURL
        }
      }
    }
  }
`;
export const _frontmatter = {
  "title": "Navigate then Change The Theme; An Experiment to useLayoutEffect React Hook",
  "date": "2020-04-04T00:00:00.000Z",
  "minRead": 5
};
const layoutProps = {
  query,
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`In a brief, useLayoutEffect React hook can prevent flash of unstyled content (FOUC) by
blocking the page till the new styles applied. Blocking the content by a synchronous process
happens after all DOM mutations. This synchronous process includes reading the layout
from the DOM, rerendering, and, as React `}<a parentName="p" {...{
        "href": "https://reactjs.org/docs/hooks-reference.html#uselayouteffect"
      }}>{`docs`}</a>{` says,
flushing the scheduled updated before the browser has a chance to paint.`}</p>
    <h2>{`Theme configuration`}</h2>
    <p>{`Before digging into the example that visualize this process in action,
we need to define a set of color modes that will change respectivly on every navigation.
I will use `}<a parentName="p" {...{
        "href": "https://theme-ui.com/"
      }}>{`theme-ui`}</a>{` as a ui library for creating user interfaces
based on a global theme configuration. It allows you to easily reference theme values
throughout the entire application.`}</p>
    <p>{`For creating color modes, there is a hook from theme-ui called `}<a parentName="p" {...{
        "href": "https://theme-ui.com/guides/color-mode-toggles"
      }}><inlineCode parentName="a">{`useColorMode`}</inlineCode></a>{` that
toggle or even cycle through a set of color modes defined in the theme file.`}</p>
    <p>{`For the example we will use two color modes light and dark:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`{
  ...
  colors: {
    // highlight-start
    modes: {
      light: {
        text: '#000',
        background: '#fff',
        primary: 'hsl(120, 100%, 40%)',
        secondary: '#000',
        accent: 'hsl(120, 100%, 90%)',
        muted: 'hsl(120, 20%, 7%)',
        gray: 'hsl(0, 0%, 75%)',
        code: 'hsla(360, 100%, 100%, 0.5)',
      },
      dark: {
        text: '#fff',
        background: 'hsl(180, 5%, 15%)',
        primary: 'hsl(180, 100%, 57%)',
        secondary: 'hsl(50, 100%, 57%)',
        accent: 'hsl(310, 100%, 57%)',
        muted: 'hsl(180, 5%, 5%)',
        gray: 'hsl(180, 0%, 70%)',
      },
      // highlight-end
    ...
    // you can define more modes here
    },
  }
  ...
}

`}</code></pre>
    <p>{`we will cycle through the modes by a function as below:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`// useColorModes.js
export default function useColorModes({ ...props }) {
  const [colorMode, setColorMode] = useColorMode();
  const modes = ['light', 'dark'];

  // highlight-start
  const applyMode = (currentMode) => {
    const i = modes.indexOf(currentMode);
    const n = (i + 1) % modes.length;
    const nextMode = modes[n];
    setColorMode(nextMode);
  };
  // highlight-end

  return { colorMode, applyMode };
}
`}</code></pre>
    <h2>{`useLayoutEffect vs useEffect`}</h2>
    <p>{`Now, we will take these theme configurations in action.
we will see the difference between the `}<inlineCode parentName="p">{`useLayoutEffect`}</inlineCode>{` and `}<inlineCode parentName="p">{`useEffect`}</inlineCode>{` when trying to toggle between these modes.`}</p>
    <p>{`According to React docs, useLayoutEffect is the same as useEffect except it synchronously fires after all DOM mutations
this will block the content in our case, while toggling between modes and flush the updates before even the browser paint any content on the page.`}</p>
    <p><inlineCode parentName="p">{`useLayoutEffect`}</inlineCode>{` and `}<inlineCode parentName="p">{`useEffect`}</inlineCode>{` takes a function as a first argument that runs and applies the side effect after the render is committed.
As a second argument it takes an array of dependencies that on changing them the effectful function runs.
Be cautious when using dependencies because it might lead to endless number of renderings.`}</p>
    <p>{`Note: the function from the first argument returns undefined if you don't return anything from it which may cause memory leaks.
Better to clean up things by explicitly returning a cleanup function for your effect.
You can read React `}<a parentName="p" {...{
        "href": "https://reactjs.org/docs/hooks-reference.html#useeffect"
      }}>{`docs`}</a>{` for detailed information.`}</p>
    <h2>{`Applying color modes with useLayoutEffect`}</h2>
    <p>{`Finally, we will apply both `}<inlineCode parentName="p">{`useLayoutEffect`}</inlineCode>{` and `}<inlineCode parentName="p">{`useEffect`}</inlineCode>{` according to the code snippet below:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`// App.js
import { ThemeProvider, ColorMode } from 'theme-ui';
import React, { useLayoutEffect, useEffect } from 'React';
import useColorModes from './userColorModes';

function App() {
  const { colorMode, applyMode } = useColorModes();

  // highlight-start
  // replace with useEffect to see the difference
  useLayoutEffect(() => {
    applyMode(colorMode);
    // passing zero length dependencies array makes the effect to happen one time
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <ColorMode />
      <div>Some content</div>
    </ThemeProvider>
  );
  // highlight-end
}
`}</code></pre>
    <h2>{`The difference`}</h2>
    <p>{`Here is the difference imported from dev tools profiler and slowed to one frame per second.
Each transition is per click and to a different article.`}</p>
    <p>{`the `}<strong parentName="p">{`first`}</strong>{` one when using `}<inlineCode parentName="p">{`useLayoutEffect`}</inlineCode>{` we can see the transition is sharp no
flicker between the color modes. The `}<strong parentName="p">{`second`}</strong>{` when using `}<inlineCode parentName="p">{`useEffect`}</inlineCode>{` the transition
caused a flicker you will notice switching between articles is not sharp like in the first.`}</p>
    <p><strong parentName="p">{`Try to focus on each gif and compare results later, don't focus on both of them at one time.`}</strong></p>
    <List sx={{
      py: 3,
      fontSize: 0
    }} mdxType="List">
  <img src={props.data.images.edges[0].node.publicURL} />
  <img src={props.data.images.edges[1].node.publicURL} />
    </List>
    <p>{`Fortunately, You can try this experiment on production now after you read this article. I am using `}<inlineCode parentName="p">{`useLayoutEffect`}</inlineCode>{`
to cycle through the color modes. Just navigate between the website content and you will see!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      